import styled from 'styled-components';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import TablePagination from 'component/ui/table/TablePagination';

import { selectSearchAddressList } from 'redux/duck/ecgTestList/ecgTestListDuck';
import { selectSearchAddressListState } from 'redux/duck/ecgTestList/ecgTestListDuckType';

function AddressItem({
  addressInput,
  onClose,
  onChangeInput,
  onTextInputSubmit,
}) {
  const searchAddressList = useShallowEqualSelector(
    selectSearchAddressList
  ) as selectSearchAddressListState;

  const searchAddressListInitState = {
    addressData: [],
    errorCode: '',
    errorMessage: '',
    pageInfo: {
      countPerPage: 0,
      currentPage: 0,
      totalCount: 0,
      totalPageCount: 0,
    },
  };

  const { addressData, errorCode, errorMessage, pageInfo } =
    searchAddressList?.data || searchAddressListInitState;

  const handleSelectAddress = (jibunAddr, zipNo) => {
    onChangeInput(jibunAddr, 'address1');
    onChangeInput(zipNo, 'postCode');
    onClose();
  };

  if (errorCode === '') {
    return (
      <Container>
        <DefaultWrapper>
          <Instruction>
            아래와 같은 조합으로 검색을 하시면 더욱 정확한 결과가 검색됩니다.
          </Instruction>
          <Instruction>도로명 + 건물번호</Instruction>
          <GuidText>예) 서울 강남구 압구정로79길 19 레인에비뉴 3층</GuidText>
          <Instruction>지역명(동/리) + 번지</Instruction>
          <GuidText>예) 청담동 121-28</GuidText>
        </DefaultWrapper>
      </Container>
    );
  }

  if (errorCode !== '0') {
    return (
      <Container>
        <ErrorWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <>
        <CountWrapper>
          <CountText>{pageInfo.totalCount}</CountText>
          <CountLabel>건</CountLabel>
        </CountWrapper>
        <AddressContainer>
          {addressData.map(({ jibunAddr, roadAddr, zipNo }, index) => (
            <AddressBlock key={index}>
              <AddressOption
                onClick={() => handleSelectAddress(roadAddr, zipNo)}>
                <Label>도로명</Label>
                <Value>{roadAddr}</Value>
              </AddressOption>
              <AddressOption
                onClick={() => handleSelectAddress(jibunAddr, zipNo)}>
                <Label>지번</Label>
                <Value>
                  {jibunAddr} | {zipNo}
                </Value>
              </AddressOption>
            </AddressBlock>
          ))}
        </AddressContainer>
        <TablePagination
          style={{
            width: '360px',
            height: 40,
            padding: '0px 10px 0px 0px',
          }}
          currentPage={pageInfo.currentPage}
          pageSize={pageInfo.countPerPage}
          currentPageItemCount={pageInfo.countPerPage}
          totalItemCount={pageInfo.totalCount}
          onChangeItemCount={() => {
            onTextInputSubmit({
              page: 1,
              addressInput,
            });
          }}
          onClickBackwardEnd={() => {
            onTextInputSubmit({ page: 1, addressInput });
          }}
          onClickBackward={() => {
            onTextInputSubmit({
              page: pageInfo.currentPage - 1,
              addressInput,
            });
          }}
          onClickForward={() => {
            onTextInputSubmit({
              page: pageInfo.currentPage + 1,
              addressInput,
            });
          }}
          onClickForwardEnd={() => {
            onTextInputSubmit({
              page: pageInfo.totalPageCount,
              addressInput,
            });
          }}
          isShowPerPage={false}
        />
      </>
    </Container>
  );
}

const Container = styled.div``;
const AddressContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 395px;
  max-height: 395px;
  overflow-y: auto;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;
`;

const CountText = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

const CountLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

const DefaultWrapper = styled.div`
  display: flex;
  margin-top: 3px;
  flex-direction: column;
`;

const Instruction = styled.div`
  padding: 7px 0 5px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.color.BLUE_GRAY};
`;

const GuidText = styled.div`
  font-size: 12px;
  font-weight: 500;

  color: ${(props) => props.theme.color.COOL_GRAY_90};
  font-family: Spoqa Han Sans Neo;
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 360px;
  overflow-wrap: break-word; /* 단어 줄바꿈 */
  word-break: break-word; /* 긴 단어 줄바꿈 */
  border-bottom: 1px solid rgba(230, 234, 236, 1);
  padding: 10px 0;
  &:hover div:first-child {
    text-decoration: underline;
  }
`;

const AddressOption = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

const Label = styled.span`
  width: 45px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.color.BLUE_GRAY};
`;

const Value = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
  font-family: Spoqa Han Sans Neo;
  width: 300px;
`;
const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 395px;
  width: 100%;
  margin-left: -10px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

export default AddressItem;
