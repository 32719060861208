import { useDispatch } from 'react-redux';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import {
  createEcgTestRequested,
  patchEcgTestRequested,
  deleteEcgTestRequested,
} from 'redux/duck/ecgTestList/ecgTestListDuck';
import AddNewTestDialog from 'component/dialog/AddNewTestDialog';

function AddNewTestDialogContainer(props) {
  const dispatch = useDispatch();

  // selector
  const ecgTestsCreateState = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.create
  );
  const ecgTestsPatchState = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.patch
  );
  const ecgTestsDeleteState = useShallowEqualSelector(
    ({ ecgTestListReducer }) => ecgTestListReducer.delete
  );
  const authState = useShallowEqualSelector((state) => state.authReducer);

  // dispatch
  const _showDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const _hideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };
  const createEcgTest = ({
    patientName,
    patientNumber,
    patientSex,
    patientPhoneNumber,
    patientBirth,
    prescriptionDuration,
    pacemaker,
    referredBy,
    note,
  }) => {
    dispatch(
      createEcgTestRequested({
        patientName,
        patientNumber,
        patientSex,
        patientPhoneNumber,
        patientBirth,
        prescriptionDuration,
        pacemaker,
        referredBy,
        note,
      })
    );
  };
  const patchEcgTest = ({ ecgTestId, patientInfo }) => {
    dispatch(patchEcgTestRequested({ ecgTestId, form: patientInfo }));
  };
  const deleteEcgTest = ({ ecgTestId }) => {
    dispatch(deleteEcgTestRequested({ ecgTestId }));
  };

  if (authState.data === null) {
    props.onClose();
  }

  return (
    <AddNewTestDialog
      {...props}
      // selector
      ecgTestsCreateState={ecgTestsCreateState}
      ecgTestsPatchState={ecgTestsPatchState}
      ecgTestsDeleteState={ecgTestsDeleteState}
      // dispatch
      showDialog={_showDialog}
      hideDialog={_hideDialog}
      createEcgTest={createEcgTest}
      patchEcgTest={patchEcgTest}
      deleteEcgTest={deleteEcgTest}
    />
  );
}

export default AddNewTestDialogContainer;
