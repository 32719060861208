import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Const from 'constant/Const';

import DateUtil from 'util/DateUtil';

import useAuthority from 'component/hook/useAuthority';
import usePrevious from 'component/hook/usePrevious';
import useValidateReportAndStatistics from 'component/hook/useValidateReportAndStatistics';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { showDialog as showDialogRequested } from 'redux/duck/dialogDuck';
import {
  patchEcgTestRequested,
  confirmEcgTestReviewRequested,
  editDoneEcgTestReviewRequested,
  getValidReportRequested,
} from 'redux/duck/ecgTestList/ecgTestListDuck';
import FinalConfirmReportDialog from 'component/dialog/FinalConfirmReportDialog';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

export const calledFromConst = {
  ECG_TEST_LIST_PAGE: 'ECG_TEST_LIST_PAGE',
  ECG_TEST_RESULT_PAGE: 'ECG_TEST_RESULT_PAGE',
};

function FinalConfirmReportDialogContainer(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const {
    calledFrom,
    ecgTestId,
    patientNumber,
    isMainEcgTest: _isMainEcgTest,
    isMedicalCheckUpTest: _isMedicalCheckUpTest,
    report,
    initConfirmedBy,
  } = params;
  const { updatedAt, createdAt, checkUp, general } = report;

  // Store States
  const {
    ecgTestsValidReportState,
    ecgTestsPatchState,
    ecgTestsConfirmState,
    ecgTestsEditDoneState,
  } = useShallowEqualSelector(({ ecgTestListReducer: state }) => ({
    ecgTestsValidReportState: state.validReport,
    ecgTestsPatchState: state.patch,
    ecgTestsConfirmState: state.confirm,
    ecgTestsEditDoneState: state.editDone,
  }));
  /**
   * PA임상용 이더라도 크레들에 올리면 isMainEcgTest가 true로 변경된다.
   * true  : 메인 ECG Test, PA임상용 크레들업로드
   * false : sub-test(PA임상용 ECG Test daily)
   */
  const isMainEcgTestFromRedux = useShallowEqualSelector(
    (state) => state.testResultReducer.ecgTest.data?.isMainEcgTest
  );
  const isMedicalCheckUpTestFromRedux = useShallowEqualSelector(
    (state) => state.testResultReducer.ecgTest.data?.isMedicalCheckUpTest
  );

  // Dispatches
  const showDialog = (dialogKey, params, callback) => {
    dispatch(showDialogRequested(dialogKey, params, callback));
  };
  const getValidReport = () => {
    dispatch(getValidReportRequested({ ecgTestId }));
  };
  const patchEcgTestConfirmedBy = ({ confirmedBy, callback }) => {
    dispatch(
      patchEcgTestRequested({ ecgTestId, form: { confirmedBy }, callback })
    );
  };
  const confirmEcgTestReview = ({ ecgTestId, reGenerateReport, callback }) => {
    dispatch(
      confirmEcgTestReviewRequested({
        ecgTestId,
        reGenerateReport,
        callback,
      })
    );
  };
  const editDoneEcgTestReview = ({ ecgTestId }) => {
    dispatch(editDoneEcgTestReviewRequested({ ecgTestId }));
  };

  // local state
  const [initPending, setInitPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);

  // custom hook
  const { relationType } = useAuthority();

  // ..etc
  const [
    //
    reportFileName,
    reportFileUrl,
  ] = useMemo(() => {
    const isCustomerUser = relationType === Const.USER_RELATION_TYPE.CUSTOMER;
    const shortTid = ecgTestId.split('-')[0];
    return [
      `${isCustomerUser ? patientNumber : shortTid}_${DateUtil.format(
        updatedAt || createdAt,
        'yyyy-MM-dd_HH-mm'
      )}.pdf`,
      isCustomerUser
        ? [general?.reportFile, checkUp?.reportFile]
        : [general?.deIdReportFile, checkUp?.deIdReportFile],
    ];
  }, [patientNumber, report]);

  let isMainEcgTest = null;
  let isMedicalCheckUpTest = null;
  if (calledFrom === calledFromConst.ECG_TEST_LIST_PAGE) {
    isMainEcgTest = _isMainEcgTest;
    isMedicalCheckUpTest = _isMedicalCheckUpTest;
  } else {
    isMainEcgTest = isMainEcgTestFromRedux;
    isMedicalCheckUpTest = isMedicalCheckUpTestFromRedux;
  }

  const { validateStt } = useValidateReportAndStatistics({
    ecgTestId,
    ecgTestInfo: {
      isMainEcgTest,
      isMedicalCheckUpTest,
    },
    callback: () => onClose(),
  });

  /**
   * 0: 행위 명사만,
   * 1: 행위 명사 + 조사,
   * 2: 다음 단계
   */
  const purposeText = useMemo(
    () =>
      relationType === Const.USER_RELATION_TYPE.PARTNER
        ? [
            intl.formatMessage({
              id: '99-purposeText-partner-01',
              description: '',
              defaultMessage: '편집',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-02',
              description: '',
              defaultMessage: '편집을',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-03',
              description: '',
              defaultMessage: '휴이노로',
            }),
          ]
        : [
            intl.formatMessage({
              id: '99-purposeText-notPartner-01',
              description: '',
              defaultMessage: '검토',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-02',
              description: '',
              defaultMessage: '검토를',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-03',
              description: '',
              defaultMessage: '담당 병원으로',
            }),
          ],
    [relationType]
  );

  const doneRequest = () => {
    if (IS_CUSTOMER_WEB) {
      confirmEcgTestReview({ ecgTestId, reGenerateReport: true });
    } else {
      editDoneEcgTestReview({ ecgTestId, relationType });
    }
  };

  const reportOnClick = useCallback(() => {
    reportFileUrl.forEach((report) => report && window.open(report));
  }, [reportFileUrl]);

  const onSubmit = useCallback(
    (newConfirmedBy) => {
      try {
        setSubmitPending(true);
        if (newConfirmedBy?.value === initConfirmedBy?.value) {
          // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
          doneRequest();
        } else {
          // 변경된 확인의 저장 처리
          patchEcgTestConfirmedBy({ confirmedBy: newConfirmedBy.value });
        }
      } catch (error) {
        console.error('확인의 처리시 오류가 발생했습니다.', error);
      }
    },
    [ecgTestId, initConfirmedBy]
  );

  useEffect(() => {
    if (open) {
      getValidReport();
    }
  }, [open]);

  // 리포트 유효성 검증 요청의 응답 처리
  // PA 임상에서는 리포트 검증을 하지 않는다.
  const prevEcgTestsValidReportState = usePrevious(ecgTestsValidReportState);
  useEffect(() => {
    if (
      prevEcgTestsValidReportState &&
      prevEcgTestsValidReportState.pending &&
      !ecgTestsValidReportState.pending
    ) {
      if (isMainEcgTest === undefined || isMainEcgTest === null) {
        console.warn('isMainEcgTest is undefined');
      }
      if (!ecgTestsValidReportState.error) {
        // # 하위 Case list
        // ## CASE1: [#PA임상] PA 임상 sub test인 경우 > ectTestsValidReportState 검증을 하지 않고 검토 완료 진행
        // ## CASE2: PA 임상이 아닌 경우
        // ## CASE2-1: 리포트 유효성 검증 통과 > 검토 완료 Dialog 팝업 open
        // ## CASE2-1: 리포트 유효성 검증 미통과 > 리포트 재생성 Dialog 팝업(리포트 재생성해야 검토 완료 가능)
        //  - CASE2-1이 되는 상황: 검토 완료 이후 비트 편집, 리포트 담기 편집을 했을 경우

        const isPAClinicalSubTest = isMainEcgTest === false;
        if (isPAClinicalSubTest) {
          // # CASE1: [#PA임상] PA 임상 sub test인 경우 > ectTestsValidReportState 검증을 하지 않고 검토 완료 진행
          //   - sub test는 리포트 검증을 하지 않는다.
          //   - sub test인 케이스: 병상에서 patch로 데이터를 수집하고 있다 1일치 데이터를 모으면 memo care에 데이터를 업로한다. 이게 sub-test이다.
          //   - sub test를 코드에서알 수 있는 방법의미: isMainEcgTest === false
          //   - 추가 정보: PA임상으로 부터온 데이터 인데 크레들에 올려서 메모케어로 업로드하면 isMainEcgTest가 true 설정된다.
          // * isMainEcgTest 정리
          //   - 병원에서 patch로 데이터를 크레들에 올린경우: isMainEcgTest -> true
          //   - PA임상에서 patch 데이터를 크레들에 올린경우: isMainEcgTest -> true
          //   - PA임상에서 patch 데이터를 AWS에서 1일치 데이터를 memo care로 upload한 경우(sub test): isMainEcgTest -> false
          setInitPending(false);
        } else {
          // # CASE2:PA 임상이 아닌 경우
          const validReportState = ecgTestsValidReportState.data === true;
          if (validReportState) {
            // # CASE2-1: 리포트 유효성 검증 통과 > 검토 완료 Dialog 팝업 open
            setInitPending(false);
          } else {
            // # CASE2-1: 리포트 유효성 검증 미통과 > 리포트 재생성 Dialog 팝업
            // 리포트 생성 Dialog 팝업
            showDialog('ConfirmDialog', {
              title: intl.formatMessage({
                id: '99-FinalConfirmReportDialog-ConfirmDialog-title-01',
                description:
                  '리포트에 반영되지 않은 환자 및 검사 정보 수정 이력이 있습니다. / There is a history of correcting patient and examination information that was not reflected in the report.',
                defaultMessage:
                  '리포트에 반영되지 않은 환자 및 검사 정보 수정 이력이 있습니다',
              }),
              message: intl.formatMessage(
                {
                  id: '99-FinalConfirmReportDialog-ConfirmDialog-message-01',
                  description:
                    '리포트를 다시 생성해주세요. 리포트 생성 후 검토 완료 상태로 변경할 수 있습니다.',
                  defaultMessage:
                    '리포트를 다시 생성해주세요. 리포트 생성 후 {purposeText} 완료 상태로 변경할 수 있습니다.',
                },
                { purposeText: purposeText[0] }
              ),
              cancelButtonText: intl.formatMessage({
                id: '99-FinalConfirmReportDialog-ConfirmDialog-cancelButtonText-01',
                description: '닫기',
                defaultMessage: '닫기',
              }),
              confirmButtonText: intl.formatMessage({
                id: '99-FinalConfirmReportDialog-ConfirmDialog-confirmButtonText-01',
                description: '다시 생성하기',
                defaultMessage: '다시 생성하기',
              }),
              onCancel: () => {
                onClose();
              },
              onSubmit: () => {
                validateStt('showDialog');
              },
            });
          }
        }
      } else {
        onClose();
      }
    }
  }, [ecgTestsValidReportState.pending]);

  // 검사 정보에 확인의 수정 요청에 대한 응답 처리
  const prevEcgTestsPatchState = usePrevious(ecgTestsPatchState);
  useEffect(() => {
    if (
      prevEcgTestsPatchState &&
      prevEcgTestsPatchState.pending &&
      !ecgTestsPatchState.pending
    ) {
      if (!ecgTestsPatchState.error) {
        // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
        doneRequest();
      } else {
        // showDialog('AlertNetworkDialog');
      }
    }
  }, [ecgTestsPatchState.pending]);

  // 검토 완료 요청의 응답 처리
  const prevEcgTestsConfirmState = usePrevious(ecgTestsConfirmState);
  useEffect(() => {
    async function ecgTestConfirmReviewCallback() {
      if (
        prevEcgTestsConfirmState &&
        prevEcgTestsConfirmState.pending &&
        !ecgTestsConfirmState.pending
      ) {
        if (!ecgTestsConfirmState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    ecgTestConfirmReviewCallback();
  }, [ecgTestsConfirmState.pending]);

  // 편집 완료 요청의 응답 처리
  const prevEcgTestsEditDoneState = usePrevious(ecgTestsEditDoneState);
  useEffect(() => {
    async function confirmEcgTestReviewRequestedCallback() {
      if (
        prevEcgTestsEditDoneState &&
        prevEcgTestsEditDoneState.pending &&
        !ecgTestsEditDoneState.pending
      ) {
        if (!ecgTestsEditDoneState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    confirmEcgTestReviewRequestedCallback();
  }, [ecgTestsEditDoneState.pending]);

  return !initPending ? (
    <FinalConfirmReportDialog
      {...{
        // Dialog Props
        open,
        params: {
          purposeText,
          reportFileName,
          reportOnClick,
          initConfirmedBy,
          onSubmit,
        },
        onClose,
        // Store States
        // Local States
        submitPending,
      }}
    />
  ) : null;
}

export default FinalConfirmReportDialogContainer;
