import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';
import { WebViewMessageConst, sendMessage } from 'constant/WebViewMessageConst';

import DateUtil, { FORMAT_TYPE as _FORMAT_TYPE } from 'util/DateUtil';
import { getIsRawDataOnly } from 'util/reduxDuck/TestResultDuckUtil';
import { handleLinkNavigation } from 'util/BrowserUtil';

import useReportFileDownload from 'component/hook/useReportFileDownload';
import useSnackbarStack from 'component/hook/useSnackbarStack';
import useAuthority from 'component/hook/useAuthority';

import { calledFromConst } from 'redux/container/dialog/FinalConfirmReportDialogContainer';

import { rotateAnim } from 'component/ui/animation/Animation';
import SplitButton from 'component/ui/button/SplitButton';
import Label from 'component/ui/label/Label';
import { MenuBase } from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import Tooltip from 'component/ui/tooltip/Tooltip';
import {
  BeatIcon,
  NotiIcon,
  LoadingIcon,
  ReportIcon,
  ReportChkIcon,
  RevertIcon,
  CheckIcon,
} from 'component/ui/icons';

import { setReportDownloadStatusCheckRequested } from 'redux/duck/ecgTestList/ecgTestListDuck';

const EcgTestDataIconWrapper = styled.div`
  width: ${({ isSidePanel }) => (isSidePanel ? '116px' : '24px')};
  height: ${({ isSidePanel }) => (isSidePanel ? '28px' : '24px')};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_50};
  background-color: ${({ emrUploadedChk, theme }) =>
    emrUploadedChk && theme.color.COOL_GRAY_50};
  gap: ${({ isSidePanel }) => isSidePanel && '2px'};
  line-height: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover,
  :active {
    background-color: ${({ theme, emrUploadedChk }) =>
      emrUploadedChk ? theme.color.COOL_GRAY_50 : theme.color.COOL_GRAY_30};
  }
`;

const StyledBeatIcon = styled(BeatIcon)`
  width: 16px;
  height: 16px;
`;

const StyledNotiIcon = styled(NotiIcon)`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.RED_70};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  path {
    fill: white;
  }
`;

const StyledRevertIcon = styled(RevertIcon)`
  width: 16px;
  height: 16px;
  ${({ disabled, theme }) =>
    disabled &&
    `
    path {
      fill: ${theme.color.COOL_GRAY_50};
    };
    cursor: not-allowed;
    :hover,
    :active {
      background-color: transparent;
    };
  `}
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  ${({ disabled, theme }) =>
    disabled &&
    `
    path {
      fill: ${theme.color.COOL_GRAY_50};
    };
    cursor: not-allowed;
    :hover,
    :active {
      background-color: transparent;
    };
  `}
`;

const StyledLoadingIcon = styled(LoadingIcon)`
  animation: ${rotateAnim} 1s linear infinite;
  cursor: auto;
`;

const StyledReportIcon = styled(ReportIcon)`
  width: 16px;
  height: 16px;
  ${({ disabled, theme }) =>
    disabled &&
    `
    path {
      fill: ${theme.color.COOL_GRAY_50};
    };
    cursor: not-allowed;
    :hover,
    :active {
      background-color: transparent;
    };
  `}
`;

const StyledReportIconChk = styled(ReportChkIcon)`
  height: 24px;
  width: 24px;
  ${({ disabled, theme }) =>
    disabled &&
    `
    path {
      fill: ${theme.color.COOL_GRAY_40};
    };

    cursor: not-allowed;
    :hover,
    :active {
      background-color: transparent
    };
  `};
`;

const EcgTestDataWrapper = styled.div`
  width: ${({ isSidePanel }) => isSidePanel && '116px'};
  height: 24px;
  border: ${({ isSidePanel, theme }) =>
    isSidePanel && `1px solid ${theme.color.COOL_GRAY_50}`};
  border-radius: ${({ isSidePanel }) => isSidePanel && '6px'};
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}
`;

// 데이터 컬럼의 버튼 그룹 설정
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ isSidePanel }) => (isSidePanel ? '8px' : '4px')};
`;

export const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;
const FORMAT_TYPE = _FORMAT_TYPE;
const { REPORT_STATUS, USER_RELATION_TYPE, ECG_TEST_STATUS } = Const;

const EcgTestDataItem = (props) => {
  const {
    ecgTest,
    validReportPending,
    medicalStaffsFetchPending,
    isSidePanel,
    handleShowDialog,
    handleRevertStatusRequested,
    loadPageData,
  } = props;

  const history = useHistory();
  const intl = useIntl();
  const { enqueueMessage } = useSnackbarStack();
  const { isReadOnly, relationType, isEditable } = useAuthority();

  const {
    tid,
    ecgTestStatus,
    estimatedInternalConfirmDatetime,
    report,
    confirmedBy,
    referredBy,
    patientNumber,
    extraFile,
    isRawDataReady,
    emrUploadedBy,
    emrUploadedDatetime,
    isMainEcgTest,
    isMedicalCheckUpTest,
  } = ecgTest;

  const isRawDataOnly = getIsRawDataOnly({
    isRawDataOnly: ecgTest.isRawDataOnly,
    isRawDataReady,
    ecgTestStatus,
  });
  const isCustomerUser = relationType === USER_RELATION_TYPE.CUSTOMER;
  const isRenderingReport = [
    REPORT_STATUS.COMPUTED,
    REPORT_STATUS.COMPUTING,
    REPORT_STATUS.REPORT_RENDERING,
  ].includes(report?.reportStatus);

  const isFailGeneratingReport = REPORT_STATUS.isFailGeneratingReport(
    report?.reportStatus
  );

  const isErroredReport = [
    Const.REPORT_STATUS.COMPUTING_FAILED,
    Const.REPORT_STATUS.REPORT_RENDERING_FAILED,
    Const.REPORT_STATUS.REPORT_ERROR,
  ].includes(report?.reportStatus);

  const isConfirmationImpossible =
    (isReadOnly && !isEditable) ||
    !report ||
    isRenderingReport ||
    isErroredReport ||
    isRawDataOnly;

  const isShowFindingsZipFileDownloadMenu = report?.reportFindingsZipFile;
  const isShowReportExtraTxtFileDownloadMenu = report?.reportExtraTxtFile;

  // 1. 검사중 + 클라우드 분석중
  if (ecgTestStatus <= ECG_TEST_STATUS.UNDER_CLOUD && !isSidePanel) {
    if (!estimatedInternalConfirmDatetime) return '-';

    if (isRawDataOnly) {
      return (
        <ButtonGroup>
          <PatientDataItem
            tid={tid}
            history={history}
            isSidePanel={isSidePanel}
          />
          {intl.formatMessage(INTL_MAP.ESTIMATED_END_DATE, {
            date: DateUtil.formatDateOnly(
              new Date(estimatedInternalConfirmDatetime),
              FORMAT_TYPE['MM-dd']
            ),
          })}
        </ButtonGroup>
      );
    }

    return intl.formatMessage(INTL_MAP.ESTIMATED_END_DATE, {
      date: DateUtil.formatDateOnly(
        new Date(estimatedInternalConfirmDatetime),
        FORMAT_TYPE['MM-dd']
      ),
    });
  }

  // 2. 검토중 + 검토완료 + 에러를 모두 통합하여 처리
  return (
    <ButtonGroup isSidePanel={isSidePanel}>
      <PatientDataItem tid={tid} history={history} isSidePanel={isSidePanel} />
      {ecgTestStatus <= ECG_TEST_STATUS.CUSTOMER_CONFIRMED && (
        <>
          <ReportItem
            tid={tid}
            isSidePanel={isSidePanel}
            isRenderingReport={isRenderingReport}
            isFailGeneratingReport={isFailGeneratingReport}
            isRawDataOnly={isRawDataOnly}
            isCustomerUser={isCustomerUser}
            report={report}
            ecgTest={ecgTest}
            extraFile={extraFile}
            isShowFindingsZipFileDownloadMenu={
              isShowFindingsZipFileDownloadMenu
            }
            isShowReportExtraTxtFileDownloadMenu={
              isShowReportExtraTxtFileDownloadMenu
            }
            emrUploadedBy={emrUploadedBy}
            emrUploadedDatetime={emrUploadedDatetime}
          />
          {/* status에 따라 '검토완료하기' 혹은 '다시 검토하기'  */}
          <RevertTestItem
            tid={tid}
            ecgTestStatus={ecgTestStatus}
            handleShowDialog={handleShowDialog}
            handleRevertStatusRequested={handleRevertStatusRequested}
            loadPageData={loadPageData}
            enqueueMessage={enqueueMessage}
            isSidePanel={isSidePanel}
            isFailGeneratingReport={isFailGeneratingReport}
            isRenderingReport={isRenderingReport}
            isConfirmationImpossible={isConfirmationImpossible}
          />
          <ConfirmTestItem
            tid={tid}
            ecgTestStatus={ecgTestStatus}
            validReportPending={validReportPending}
            medicalStaffsFetchPending={medicalStaffsFetchPending}
            confirmedBy={confirmedBy}
            referredBy={referredBy}
            handleShowDialog={handleShowDialog}
            loadPageData={loadPageData}
            enqueueMessage={enqueueMessage}
            isMedicalCheckUpTest={isMedicalCheckUpTest}
            isMainEcgTest={isMainEcgTest}
            isSidePanel={isSidePanel}
            isRenderingReport={isRenderingReport}
            isConfirmationImpossible={isConfirmationImpossible}
            isFailGeneratingReport={isFailGeneratingReport}
            report={report}
            patientNumber={patientNumber}
          />
        </>
      )}
    </ButtonGroup>
  );
};

export default EcgTestDataItem;

const DataTooltip = ({ isSidePanel, title, children, ...props }) => {
  if (isSidePanel) return children;

  return (
    <Tooltip title={title} placement="bottom" {...props} arrow>
      {children}
    </Tooltip>
  );
};

const PatientDataItem = ({ isSidePanel, tid, history }) => {
  const intl = useIntl();

  return (
    <DataTooltip
      title={intl.formatMessage(INTL_MAP.PATIENT_DATA)}
      isSidePanel={isSidePanel}>
      <EcgTestDataIconWrapper
        onClick={(event) => {
          event.stopPropagation();
          handleLinkNavigation({ event, url: `/test/${tid}`, history });
        }}
        isSidePanel={isSidePanel}>
        <StyledBeatIcon />
        {isSidePanel && (
          <Label text={intl.formatMessage(INTL_MAP.PATIENT_DATA)} />
        )}
      </EcgTestDataIconWrapper>
    </DataTooltip>
  );
};

const ReportItem = ({
  tid,
  isSidePanel,
  isRenderingReport,
  isFailGeneratingReport,
  isRawDataOnly,
  isCustomerUser,
  report,
  extraFile,
  ecgTest,
  isShowFindingsZipFileDownloadMenu,
  isShowReportExtraTxtFileDownloadMenu,
  emrUploadedBy,
  emrUploadedDatetime,
}) => {
  const {
    general: {
      reportFile: generalReportFile,
      deIdReportFile: generalDeIdReportFile,
      reportImageFiles: generalReportImageFiles,
      reportImageZipFile: generalReportImageZipFile,
    } = {},
    checkUp: {
      reportFile: checkUpReportFile,
      deIdReportFile: checkUpDeIdReportFile,
      reportImageFiles: checkUpReportImageFiles,
      reportImageZipFile: checkUpReportImageZipFile,
    } = {},
  } = report || {};

  const intl = useIntl();
  const {
    downloadReportFindingsZipFile,
    downloadReportExtraTxtFile,
    downloadReportImageFiles,
    downloadReportImageZipFile,
  } = useReportFileDownload();

  const dispatch = useDispatch();
  const handleReportDownloadStatusCheck = (uploadStatus) => {
    dispatch(
      setReportDownloadStatusCheckRequested(tid, {
        isUploadedToEmr: uploadStatus,
      })
    );
  };

  if (isFailGeneratingReport && !isSidePanel)
    return (
      <DataTooltip
        isSidePanel={isSidePanel}
        title={intl.formatMessage(INTL_MAP.RENDERING_ERROR_03, { br: <br /> })}
        option={{ maxWidth: 270, width: 270 }}>
        <EcgTestDataWrapper disabled isSidePanel={isSidePanel}>
          <StyledNotiIcon />
          <Label
            status="bad"
            text={intl.formatMessage(INTL_MAP.RENDERING_ERROR_01)}
          />
        </EcgTestDataWrapper>
      </DataTooltip>
    );

  if (isRenderingReport)
    return (
      <EcgTestDataIconWrapper isSidePanel={isSidePanel}>
        <StyledLoadingIcon />
        {isSidePanel && (
          <Label text={intl.formatMessage(INTL_MAP.RENDERING_REPORT)} />
        )}
      </EcgTestDataIconWrapper>
    );

  if (isFailGeneratingReport)
    return (
      <DataTooltip
        title={intl.formatMessage(INTL_MAP.RENDERING_ERROR_03, { br: <br /> })}
        option={{ maxWidth: 270, width: 270 }}>
        <EcgTestDataWrapper disabled isSidePanel={isSidePanel}>
          <StyledNotiIcon />
          <Label
            status="bad"
            text={intl.formatMessage(INTL_MAP.RENDERING_ERROR_02)}
          />
        </EcgTestDataWrapper>
      </DataTooltip>
    );

  const onClickViewPdf = (event) => {
    if (isRawDataOnly || !report) return;

    event.stopPropagation();

    sendMessage(WebViewMessageConst.REPORT_CLICK(tid));

    const generalReportFileUrl = isCustomerUser
      ? generalReportFile
      : generalDeIdReportFile;
    const checkUpReportFileUrl = isCustomerUser
      ? checkUpReportFile
      : checkUpDeIdReportFile;

    if (generalReportFileUrl) {
      window.open(generalReportFileUrl, 'generalReportFile');
    }

    if (checkUpReportFileUrl) {
      window.open(checkUpReportFileUrl, 'checkUpReportFile');
    }

    if (extraFile) {
      window.open(extraFile, 'extraFile');
    }
    handleReportDownloadStatusCheck(true);
  };

  if (!isSidePanel) {
    return (
      <DataTooltip
        title={
          ecgTest?.isUploadedToEmr ? (
            <TooltipTitleWrapper>
              {intl.formatMessage(INTL_MAP.REPORT_PDF_DOWNLOADED_TOOLTIP)}
              <div>
                {intl.formatMessage(
                  INTL_MAP.REPORT_LAST_DOWNLOAD_PERSON_DATETIME,
                  {
                    Viewer: emrUploadedBy?.firstName,
                  }
                )}
                {`, ${DateUtil.formatDateOnly(emrUploadedDatetime ?? 0)}`}
              </div>
            </TooltipTitleWrapper>
          ) : (
            intl.formatMessage(INTL_MAP.REPORT_PDF_TOOLTIP)
          )
        }
        isSidePanel={isSidePanel}>
        <EcgTestDataIconWrapper
          emrUploadedChk={ecgTest?.isUploadedToEmr}
          isSidePanel={isSidePanel}
          onClick={onClickViewPdf}>
          {ecgTest?.isUploadedToEmr ? (
            <StyledReportIconChk disabled={isRawDataOnly || !report} />
          ) : (
            <StyledReportIcon disabled={isRawDataOnly || !report} />
          )}
        </EcgTestDataIconWrapper>
      </DataTooltip>
    );
  }

  return (
    <SplitButton
      text={intl.formatMessage(INTL_MAP.REPORT)}
      icon={<ReportIcon />}
      onClick={onClickViewPdf}
      groupProps={{ style: { width: 116 } }}
      anchorProps={{
        style: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }}>
      <MenuBase style={{ width: 140 }}>
        <MenuItem
          title={intl.formatMessage(INTL_MAP.REPORT_PDF)}
          onClick={onClickViewPdf}
        />
        <MenuItem
          title={intl.formatMessage(INTL_MAP.REPORT_JPG)}
          onClick={async () => {
            sendMessage(WebViewMessageConst.REPORT_CLICK(tid));

            if (generalReportImageFiles && generalReportImageFiles.length > 0) {
              await downloadReportImageFiles(
                generalReportImageFiles,
                handleReportDownloadStatusCheck
              );
            } else if (generalReportImageZipFile) {
              await downloadReportImageZipFile(
                generalReportImageZipFile,
                handleReportDownloadStatusCheck
              );
            }

            if (checkUpReportImageFiles && checkUpReportImageFiles.length > 0) {
              await downloadReportImageFiles(
                checkUpReportImageFiles,
                handleReportDownloadStatusCheck
              );
            } else if (generalReportImageZipFile) {
              await downloadReportImageZipFile(
                checkUpReportImageZipFile,
                handleReportDownloadStatusCheck
              );
            }

            if (extraFile) {
              window.open(extraFile, 'extraFile');

              handleReportDownloadStatusCheck(true);
            }
          }}
        />

        {isShowFindingsZipFileDownloadMenu && (
          <MenuItem
            title={intl.formatMessage(INTL_MAP.REPORT_ZIP)}
            onClick={() => {
              const { reportFindingsZipFile } = report;

              sendMessage(WebViewMessageConst.REPORT_CLICK(tid));

              downloadReportFindingsZipFile(
                reportFindingsZipFile,
                handleReportDownloadStatusCheck
              );
            }}
          />
        )}

        {isShowReportExtraTxtFileDownloadMenu && (
          <MenuItem
            title={intl.formatMessage(INTL_MAP.REPORT_TXT)}
            onClick={() => {
              const { reportExtraTxtFile } = report;

              sendMessage(WebViewMessageConst.REPORT_CLICK(tid));

              downloadReportExtraTxtFile(
                reportExtraTxtFile,
                handleReportDownloadStatusCheck
              );
            }}
          />
        )}
      </MenuBase>
    </SplitButton>
  );
};

const RevertTestItem = ({
  tid,
  ecgTestStatus,
  handleShowDialog,
  handleRevertStatusRequested,
  loadPageData,
  enqueueMessage,
  isSidePanel,
  isFailGeneratingReport,
  isRenderingReport,
  isConfirmationImpossible,
}) => {
  const intl = useIntl();
  if (ecgTestStatus !== ECG_TEST_STATUS.CUSTOMER_CONFIRMED) return null;
  if (isFailGeneratingReport && !isSidePanel) return null;
  return (
    <DataTooltip
      title={
        isRenderingReport
          ? intl.formatMessage(INTL_MAP.REPORT_IS_BEING_GENERATED)
          : isConfirmationImpossible
          ? intl.formatMessage(INTL_MAP.REVERT_TEST_NO_PERMISSION)
          : intl.formatMessage(INTL_MAP.REVERT_TEST)
      }
      isSidePanel={isSidePanel && !isConfirmationImpossible}>
      <EcgTestDataIconWrapper
        isSidePanel={isSidePanel}
        onClick={(event) => {
          if (isConfirmationImpossible) return;

          event.stopPropagation();
          handleShowDialog({
            dialogKey: 'ConfirmDialog',
            params: {
              title: intl.formatMessage(INTL_MAP.CONFIRM_REPORT_TITLE),
              message: intl.formatMessage(INTL_MAP.CONFIRM_REPORT_MESSAGE),
              confirmButtonText: intl.formatMessage(
                INTL_MAP.CONFIRM_REPORT_CONFIRM_BUTTON_LABEL
              ),

              onSubmit: () => {
                handleRevertStatusRequested({
                  ecgTestId: tid,
                  callback: () => {
                    loadPageData();
                    enqueueMessage(
                      intl.formatMessage(
                        INTL_MAP.CHANGED_STATUS_TO_CLOUD_CONFIRMED
                      )
                    );
                  },
                });
              },
            },
          });
        }}>
        <StyledRevertIcon disabled={isConfirmationImpossible} />
        {isSidePanel && (
          <Label
            text={intl.formatMessage(INTL_MAP.REVERT_TEST)}
            status={isConfirmationImpossible ? 'disabled' : 'normal'}
          />
        )}
      </EcgTestDataIconWrapper>
    </DataTooltip>
  );
};

const ConfirmTestItem = ({
  tid,
  ecgTestStatus,
  validReportPending,
  medicalStaffsFetchPending,
  confirmedBy,
  referredBy,
  handleShowDialog,
  loadPageData,
  enqueueMessage,
  isMainEcgTest,
  isMedicalCheckUpTest,
  isSidePanel,
  isRenderingReport,
  isConfirmationImpossible,
  isFailGeneratingReport,
  report,
  patientNumber,
}) => {
  const intl = useIntl();

  if (ecgTestStatus !== ECG_TEST_STATUS.CLOUD_CONFIRMED) return null;
  if (isFailGeneratingReport && !isSidePanel) return null;

  const disabledStatus =
    isConfirmationImpossible || validReportPending || medicalStaffsFetchPending;

  return (
    <DataTooltip
      title={
        isRenderingReport
          ? intl.formatMessage(INTL_MAP.REPORT_IS_BEING_GENERATED)
          : isConfirmationImpossible
          ? intl.formatMessage(INTL_MAP.CONFIRM_TEST_NO_PERMISSION)
          : intl.formatMessage(INTL_MAP.CONFIRM_TEST)
      }
      isSidePanel={isSidePanel && !isConfirmationImpossible}>
      <EcgTestDataIconWrapper
        isSidePanel={isSidePanel}
        onClick={(event) => {
          if (disabledStatus) return;

          event.stopPropagation();
          const initConfirmedBy = (() => {
            if (confirmedBy && !confirmedBy.username.includes('DELETE')) {
              return {
                key: confirmedBy.username,
                label: confirmedBy.firstName,
                value: confirmedBy.username,
              };
            } else if (referredBy && !referredBy.username.includes('DELETE')) {
              return {
                key: referredBy.username,
                label: referredBy.firstName,
                value: referredBy.username,
              };
            }
            return null;
          })();
          // 검토완료 Dialog 팝업
          handleShowDialog({
            dialogKey: 'FinalConfirmReportDialog',
            params: {
              calledFrom: calledFromConst.ECG_TEST_LIST_PAGE,
              ecgTestId: tid,
              patientNumber,
              isMainEcgTest,
              isMedicalCheckUpTest,
              report,
              initConfirmedBy,
            },
            callback: () => {
              loadPageData();
              enqueueMessage(
                intl.formatMessage(
                  INTL_MAP.CHANGED_STATUS_TO_CUSTOMER_CONFIRMED
                )
              );
            },
          });
        }}>
        <StyledCheckIcon disabled={disabledStatus} />
        {isSidePanel && (
          <Label
            text={intl.formatMessage(INTL_MAP.CONFIRM_TEST)}
            status={disabledStatus ? 'disabled' : 'normal'}
          />
        )}
      </EcgTestDataIconWrapper>
    </DataTooltip>
  );
};

/**
 * - RENDERING_ERROR_01: 생성오류
 * - RENDERING_ERROR_02: 리포트 생성오류
 * - RENDERING_ERROR_03: 리포트 생성 중 오류가 발생했습니다. 휴이노 고객센터 (02-3443-3160)로 문의해주세요.
 * - RENDERING_REPORT: 리포트 생성 중
 * - CONFIRM_REPORT_TITLE: 검사를 다시 검토하시겠습니까?
 * - CONFIRM_REPORT_MESSAGE: 검사의 상태가 [검토 중]으로 변경됩니다.
 * - CONFIRM_REPORT_CONFIRM_BUTTON_LABEL: 다시 검토하기
 * - REPORT: 리포트 보기
 * - PATIENT_DATA: 환자 데이터
 * - REVERT_TEST: 다시 검토하기
 * - CONFIRM_TEST_NO_PERMISSION: 검토 완료 권한이 없습니다.
 * - REVERT_TEST_NO_PERMISSION: 다시 검토하기 권한이 없습니다.
 * - CONFIRM_TEST: 검토 완료하기
 * - ESTIMATED_END_DATE: {date} 완료예정
 * - CHANGED_STATUS_TO_CLOUD_CONFIRMED: 검토중으로 변경되었습니다.
 * - CHANGED_STATUS_TO_CUSTOMER_CONFIRMED: 검토완료로 변경되었습니다.
 */
const INTL_MAP = defineMessages({
  RENDERING_ERROR_01: {
    id: '03-EcgTestListFragment-EcgTestDataItem-reportItem-renderingError-01',
    description: '생성오류',
    defaultMessage: '생성오류',
  },
  RENDERING_ERROR_02: {
    id: '03-EcgTestListFragment-EcgTestDataItem-reportItem-renderingError-02',
    description: '리포트 생성오류',
    defaultMessage: '리포트 생성오류',
  },
  RENDERING_ERROR_03: {
    id: '99-AlertDialog-ReportError-message',
    description: '',
    defaultMessage:
      '리포트 생성 중 오류가 발생했습니다. 휴이노 고객센터 {br} (02-3443-3160)로 문의해주세요.',
  },
  RENDERING_REPORT: {
    id: '05-TestReviewingFragment-TableData-status-Label-02',
    description: '리포트 생성 중',
    defaultMessage: '리포트 생성 중',
  },
  CONFIRM_REPORT_TITLE: {
    id: '99-TestResultPage-ConfirmDialog-title-02',
    description: '검사를 다시 검토하시겠습니까?',
    defaultMessage: '검사를 다시 검토하시겠습니까?',
  },
  CONFIRM_REPORT_MESSAGE: {
    id: '99-TestResultPage-ConfirmDialog-message-02',
    description: '검사의 상태가 [검토 중]으로 변경됩니다.',
    defaultMessage: '검사의 상태가 [검토 중]으로 변경됩니다.',
  },
  CONFIRM_REPORT_CONFIRM_BUTTON_LABEL: {
    id: '99-TestResultPage-ConfirmDialog-confirmButtonText-02',
    description: '다시 검토하기',
    defaultMessage: '다시 검토하기',
  },
  REPORT: {
    id: '08-TestResultPage-Button-ViewReport-title',
    description: '리포트 보기 / Report',
    defaultMessage: '리포트 보기',
  },
  REPORT_PDF_TOOLTIP: {
    id: '08-TestResultPage-ViewReport-Tooltip-title',
    description: '리포트 보기(PDF) / View Report(PDF)',
    defaultMessage: '리포트 보기(PDF)',
  },
  REPORT_PDF: {
    id: '08-TestResultPage-ViewReport-title',
    description: 'PDF 보기 / View PDF',
    defaultMessage: 'PDF 보기',
  },
  REPORT_PDF_DOWNLOADED_TOOLTIP: {
    id: '08-TestResultPage-Report-Download-Tooltip-title',
    description: '리포트 조회/다운로드 완료',
    defaultMessage: '리포트 조회/다운로드 완료',
  },
  REPORT_LAST_DOWNLOAD_PERSON_DATETIME: {
    id: '03-EcgTestListFragment-TableSidePanel-report-last-download-person-ViewedDate-tooltip',
    description: '마지막 조회 : 김메모',
    defaultMessage: '마지막 조회 : {Viewer}',
  },
  REPORT_JPG: {
    id: '08-TestResultPage-DownloadReport-title',
    description: 'JPG 다운로드 / Download JPG',
    defaultMessage: 'JPG 다운로드',
  },
  REPORT_ZIP: {
    id: '08-TestResultPage-DownloadEMRZip-title',
    description: 'ZIP 다운로드 / Download ZIP',
    defaultMessage: 'ZIP 다운로드',
  },
  REPORT_TXT: {
    id: '08-TestResultPage-DownloadEMRTxt-title',
    description: 'TXT 다운로드 / Download TXT',
    defaultMessage: 'TXT 다운로드',
  },
  PATIENT_DATA: {
    id: '03-EcgTestListFragment-EcgTestDataItem-Button-title-01',
    description: '환자 데이터 보기 / Patient Data',
    defaultMessage: '환자 데이터 보기',
  },
  REVERT_TEST: {
    id: '08-TestResultPage-Button-StatusRollback-title',
    description: '다시 검토하기',
    defaultMessage: '다시 검토하기',
  },
  CONFIRM_TEST_NO_PERMISSION: {
    id: '99-TestReviewingFragment-TableData-status-Tooltip-confirm',
    description: '검토 완료 권한이 없습니다.',
    defaultMessage: '검토 완료 권한이 없습니다.',
  },
  REVERT_TEST_NO_PERMISSION: {
    id: '03-EcgTestListFragment-EcgTestDataItem-Tooltip-01',
    description: '다시 검토하기 권한이 없습니다.',
    defaultMessage: '다시 검토하기 권한이 없습니다.',
  },
  REPORT_IS_BEING_GENERATED: {
    id: '03-EcgTestListFragment-EcgTestDataItem-Tooltip-02',
    description: '리포트를 생성중입니다.',
    defaultMessage: '리포트를 생성중입니다.',
  },
  CONFIRM_TEST: {
    id: '05-TestReviewingFragment-TableData-status-Button-confirm',
    description: '검토 완료하기',
    defaultMessage: '검토 완료하기',
  },
  ESTIMATED_END_DATE: {
    id: '03-EcgTestListFragment-EcgTestDataItem-01',
    description: '03-25 완료예정',
    defaultMessage: '{date} 완료예정',
  },
  CHANGED_STATUS_TO_CLOUD_CONFIRMED: {
    id: '03-EcgTestListFragment-EcgTestDataItem-02',
    description: '검토중으로 변경되었습니다.',
    defaultMessage: '검토중으로 변경되었습니다.',
  },
  CHANGED_STATUS_TO_CUSTOMER_CONFIRMED: {
    id: '03-EcgTestListFragment-EcgTestDataItem-03',
    description: '검토완료로 변경되었습니다.',
    defaultMessage: '검토완료로 변경되었습니다.',
  },
});
